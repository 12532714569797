<!-- table single choice -->
<template>
  <div class="identityType-filter">
    <el-dropdown trigger="click"  @command="handleCommand">
      <span class="el-dropdown-link" style="color:#909399;">
        {{text}}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :command="item.arrValue" class="identityType-active" v-for="(item,index) in filters" :key="index">
          <span :style="{color:currentType == item.arrValue[0] ?'#66b1ff':''}">{{item.text}}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentType:''
    };
  },
  props:['text','filters'],
  components: {},
  computed: {},
  methods: {
     handleCommand(command) {
      this.currentType = command[0];
      this.$emit('command',command)
    },
  }
}

</script>
<style lang='scss' scoped>
.identityType-filter{
  .identityType-active{
    background-color: #ecf5ff!important;
    color: #66b1ff!important;
    &:hover{
      background-color: #ecf5ff!important;
    color: #66b1ff!important;
    }
  }
}
</style>