<!--  -->
<template>
  <div> 
    <i class="dot" v-show="status==0" style="color:#67C23A;">&bull;</i>
    <i class="dot" v-show="status==1" style="color:#E6A23C;">&bull;</i>
    <i class="dot" v-show="status==2" style="color:#F56C6C;">&bull;</i>
    <span style="vertical-align:middle;">{{statusOptions[status]}}</span>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  data () {
    return {
    };
  },
  props:['status'],
  components: {},
  computed: {
    ...mapState({
      statusOptions:state => state.dictionary.statusOptions
    })
  },
  methods: {}
}

</script>
<style lang='scss' scoped>
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
</style>