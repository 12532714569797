import instance from './axios.js'

// 用户列表
export const accountList = (para)=>{
  return instance.get('/account/accountList',{params:para})
}
// 用户信息
export const getProfile = (para)=>{
  return instance.get('/account/profile',{params:para})
}
// 修改状态
export const setStatus = (para)=>{
  return instance.post('/account/setStatus',para)
}
// 用户列表
export const unregisterList = (para)=>{
  return instance.get('/account/unRegisterList',{params:para})
}
// 通过注销
export const approveUnRegister = (para)=>{
  return instance.post('/account/approveUnRegister',para)
}
// 拒绝注销
export const rejectUnRegister = (para)=>{
  return instance.post('/account/rejectUnRegister',para)
}