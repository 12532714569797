<!-- 申请注销用户列表 -->
<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="refresh">
    </table-search>
    <div class="table">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        @sort-change="sortChange"
        @filter-change="filterChange"
        style="width: 100%">
        <el-table-column
          prop="accountId"
          sortable="custom"
          label="用户ID">
        </el-table-column>
        <!-- <el-table-column
          prop="nickName"
          label="用户名字">
          <template slot-scope="scope">
            <span v-if="!scope.row.identity">/</span>
            <span v-if="scope.row.identity === 'company'">{{scope.row.companyVo && scope.row.companyVo.companyName}}</span>
            <span v-if="scope.row.identity === 'user'">{{scope.row.userVo && scope.row.userVo.realName}}</span>
            <span v-if="scope.row.identity === 'lawyer'">{{scope.row.lawyerVo && scope.row.lawyerVo.realName}}</span>
            <span v-if="scope.row.identity === 'expert_party'">{{scope.row.expertVo && scope.row.expertVo.nickName}}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="phoneNumber"
          label="注册手机">
        </el-table-column>
        <el-table-column
          prop="registerSource"
          label="注册来源">
          <template slot-scope="scope">
            <span style="color:#409EFF;" v-show="scope.row.registerSource=='1'">安卓</span>
            <span style="color:#67C23A;" v-show="scope.row.registerSource=='2'">IOS</span>
            <span style="color:#E6A23C;" v-show="scope.row.registerSource=='3'">后台</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="userStatus"
          :filters="statusFilter"
          column-key="userStatus"
          label="状态">
          <template slot-scope="scope">
            <status-field :status="scope.row.userStatus"></status-field>
          </template>
        </el-table-column>
        <el-table-column
          prop="reason"
          label="拒绝原因">
        </el-table-column>
        <el-table-column
          prop="userCreateTime"
          label="注册时间">
        </el-table-column>
        <el-table-column
          prop="applyTime"
          label="申请时间">
        </el-table-column>
        <el-table-column
          prop="val"
          label="操作">
          <template slot-scope="scope">
            <div>
              <el-button type="text" @click="checkHandle(scope.row)">查看信息</el-button>
            </div>
            <div>
              <el-button v-show="scope.row.userStatus != '3'" type="text" @click="allowHandle(scope.row)">通过注销</el-button>
            </div>
            <div>
              <el-button type="text" v-show="scope.row.userStatus != '3'" @click="refuseHandle(scope.row)">拒绝注销</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          :current-page="form.page"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <viewinfo-dialog ref="viewinfoDialog" :info="info"></viewinfo-dialog>
    <el-dialog title="拒绝通过" :visible.sync="refuseVisible" width="40%">
      <el-form :model="form">
        <el-form-item label="拒绝原因:">
          <el-input type="textarea" v-model="reasonValue" autocomplete="off" placeholder="请输入拒绝原因" style="width:80%" rows="4"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="refuseVisible = false">取 消</el-button>
        <el-button type="primary" @click="ensureRefuse">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import ViewinfoDialog from '../../components/dialog/viewinfo-dialog.vue'
import StatusField from '../../components/table/status-field.vue'
import SingleSelector from '../../components/table/single-selector.vue'
import {unregisterList,approveUnRegister,rejectUnRegister} from '../../api/member.js'
import {applyDetail} from '../../api/verify.js'
import {mapState} from 'vuex'
export default {
  data () {
    return {
      refuseVisible:false,
      reasonValue:'',
      refuseId:-1,
      info:{
        phoneNumber:'',
        registerSource:''
      },
      accountId:'',
      loading:false,
      total:0,
      accountTypeOptions:{
        '1':'未认证',
        '2':'委托方',
        '3':'服务方',
        '4':'专家团'
      },
      accountDetailTypeOptions:{
        '1':'个人',
        '2':'企业',
        '3':'律师'
      },
      conditions:[
        {
            value: 'phoneNumber',
            label: '注册手机'
        },
        {
            value: 'accountId',
            label: '用户ID'
        },
      ],
      form:{
        page:1,
        perPage:10,
        orderBy:'accountIdDesc',
        userStatus:'',
        identity:'',
        applyStatus:''
      },
      tableData:[
      ],
      multipleSelection:[],
      authenFilter:[
        {text: '全部',arrValue:['0','','']}, 
        {text: '未认证',arrValue:['1','no',null]}, 
        {text: '认证个人',arrValue:['2','user','1']}, 
        {text: '认证企业',arrValue:['3','company','1']},
        {text: '认证律师',arrValue:['4','lawyer','1']},
        {text: '认证企业-专家团',arrValue:['5','expert_party','1']},
        {text: '认证个人-审核中',arrValue:['6','user','0']},
        {text: '认证企业-审核中',arrValue:['7','company','0']},
        {text: '认证律师-审核中', arrValue:['8','lawyer','0']},
        {text: '认证个人-审核拒绝',arrValue:['9','user','2']},
        {text: '认证企业-审核拒绝',arrValue:['10','company','2']},
        {text: '认证律师-审核拒绝',arrValue:['11','lawyer','2']},
        ],//认证信息
        identityTypeFlag:false,
    };
  },
  components: {
    TableSearch,
    ViewinfoDialog,
    StatusField,
    SingleSelector
  },
  computed: {
    ...mapState({
      statusOptions:state => state.dictionary.statusOptions,
      identityType:state => state.dictionary.identityType,
      applyStatus:state => state.dictionary.applyStatus
    }),
    statusFilter:function(){
      let res = [];
      for(let key in this.statusOptions){
        res.push({
          value:key,
          text:this.statusOptions[key]
        })
      }
      return res;
    }
  },
  methods: {
    // handleCommand(command) {
    //   this.form.identity = command[1];
    //   this.form.applyStatus = command[2];
    //   this.getList();
    // },
    searchRes(para){
      this.form = Object.assign({
        page:1,
        perPage:this.form.perPage,
        orderBy:this.form.orderBy,
        userStatus:this.form.userStatus,
        identity:this.form.identity,
        applyStatus:this.form.applyStatus
      },para)
      this.getList();
    },
    refresh(){
      this.getList();
    },
    getList(){
      this.loading = true;
      unregisterList(this.form).then(res=>{
        let data = res.data;
        if(data.code ==='000'){
          this.tableData = data.content.list;
          this.loading = false;
          this.total = data.content.total;
        }else{
          console.log(data.desc)
        }
      })
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage = val;
      this.form.page = 1;
      this.getList();
    },
    checkHandle(row){
      this.getDetail(row);
      this.$refs.viewinfoDialog.dialogVisible = true;
    },
    getDetail(row){
      let para={
        applyId:row.applyId,
        type:row.identity
      }
      applyDetail(para).then(res=>{
        let data = res.data;
        if(data.code==='000'){
          if(para.type === 'expert_party'){
            this.info = row;
          }else{
            this.info = data.content || {};
            this.info.loginPhoneNumber = row.phoneNumber;
            this.info.registerSource = row.registerSource;
          }
          this.info.type = row.identity;
          
        }else{
          console.log(data.desc)
        }
      })
    },
    allowHandle(row){
      this.$confirm('此操作将通过注销申请, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          approveUnRegister({id:row.id}).then(res=>{
            let data = res.data;
            if(data.code==='000'){
              this.$message.success(data.desc);
              this.getList();
            }else{
              this.$message.warning(data.desc);
            }
          })
        })
    },
    refuseCancel(){
      this.refuseVisible = false;
      this.reasonValue = '';
    },
    refuseHandle(row){
      this.refuseVisible = true;
      this.refuseId = row.id;
    },
    ensureRefuse(){
      rejectUnRegister({id:this.refuseId,reason:this.reasonValue}).then(res=>{
        let data = res.data;
        if(data.code==='000'){
          this.$message.success(data.desc);
          this.refuseVisible = false;
          this.reasonValue = '';
          this.getList();
        }else{
          this.$message.warning(data.desc);
        }
      })
    },
    sortChange(column, prop, order ){
      let key="";
      let order2 = column.order === 'descending'?'Desc':'Asc';
      if(column.prop === 'id'){
        key = 'accountId' + order2;
      }else{
        key = column.prop + order2;
      }
      
      this.form.orderBy = key;
      this.getList();
    },
    filterChange(filters){
      if(filters.userStatus){
        this.form.userStatus = filters.userStatus.join();
      }
      this.form.page = 1;
      this.getList();
    }
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: space-between;
  margin:15px 0;
}
</style>