<template lang="html">
<el-dialog
  title="查看信息222"
  :visible.sync="dialogVisible"
  width="50%">
  <div>
  	<!-- <h1>基本信息:</h1>
  	<ul class="flex-row" style="margin:15px 0;">
  		<li style="margin-right: 30px;">登录手机号: {{info.phoneNumber}}</li>
  		<li>注册设备: {{registerSourceOptions[info.registerSource]}}</li>
  	</ul> -->
    <el-descriptions title="基本信息">
      <el-descriptions-item label="登录手机号">{{info.loginPhoneNumber}}</el-descriptions-item>
      <el-descriptions-item label="注册设备">{{registerSourceOptions[info.registerSource]}}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="认证信息" v-if="info.type==='company'">
		    <el-descriptions-item label="认证时间">{{info.createTime | dateTimeFormat}}</el-descriptions-item>
		    <el-descriptions-item label="企业名称">{{info.companyName}}</el-descriptions-item>
		    <el-descriptions-item label="成立日期">{{info.foundTime}}</el-descriptions-item>
		    <el-descriptions-item label="注册资本">{{info.registerCapital |changeToThree}} 元</el-descriptions-item>
		    <el-descriptions-item label="统一社会信用代码">{{info.unifiedSocialCode}}</el-descriptions-item>
		    <el-descriptions-item label="企业联系人">{{info.contactPersonName}}</el-descriptions-item>
		    <el-descriptions-item label="企业联系电话">{{info.contactPhoneNumber}}</el-descriptions-item>
		    <el-descriptions-item label="企业邮箱">{{info.email}}</el-descriptions-item>
		    <el-descriptions-item label="企业区域">{{provinceObj[info.provinceId]}}&nbsp;{{cityObj[info.cityId]}}&nbsp;{{areaObj[info.areaId]}}</el-descriptions-item>
		    <el-descriptions-item label="详细地址">{{info.detailAddress}}</el-descriptions-item>
		</el-descriptions>
    <el-descriptions title="认证信息" v-if="info.type==='lawyer'">
		    <el-descriptions-item label="认证时间">{{info.createTime | dateTimeFormat}}</el-descriptions-item>
		    <el-descriptions-item label="真实姓名">{{info.realName}}</el-descriptions-item>
		    <el-descriptions-item label="身份证号">{{info.idCardNumber}}</el-descriptions-item>
        <el-descriptions-item label="联系手机">{{info.phoneNumber}}</el-descriptions-item>
		    <el-descriptions-item label="电子邮箱">{{info.email}}</el-descriptions-item>
		    <el-descriptions-item label="挂证律所">{{info.lawFirmName}}</el-descriptions-item>
		    <el-descriptions-item label="首次执业时间">{{info.firstWorkTime}}</el-descriptions-item>
        <el-descriptions-item label="执业证号">{{info.licenceNumber}}</el-descriptions-item>
		    <el-descriptions-item label="服务区域">{{provinceObj[info.provinceId]}}&nbsp;{{cityObj[info.cityId]}}&nbsp;{{areaObj[info.areaId]}}</el-descriptions-item>
        <el-descriptions-item label="擅长领域">{{info.expertAreaName}}</el-descriptions-item>
		</el-descriptions>
    <el-descriptions title="" v-if="info.type==='lawyer'">
      <el-descriptions-item label="自我介绍">{{info.introduction}}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="认证信息" v-if="info.type==='user'">
		    <el-descriptions-item label="认证时间">{{info.createTime | dateTimeFormat}}</el-descriptions-item>
		    <el-descriptions-item label="真实姓名">{{info.realName}}</el-descriptions-item>
		    <el-descriptions-item label="身份证号">{{info.idCardNumber}}</el-descriptions-item>
        <el-descriptions-item label="联系手机">{{info.phoneNumber}}</el-descriptions-item>
		    <el-descriptions-item label="电子邮箱">{{info.email}}</el-descriptions-item>
		</el-descriptions>
     <el-descriptions title="认证信息" :column="2" v-if="info.type==='expert_party'">
		    <el-descriptions-item label="开通时间">{{info.approveTime | dateTimeFormat}}</el-descriptions-item>
		    <el-descriptions-item label="所属企业">{{info.expertVo && info.expertVo.relyAccountName}}</el-descriptions-item>
		    <el-descriptions-item label="专家编号">{{info.expertVo && info.expertVo.number}}</el-descriptions-item>
        <el-descriptions-item label="专家别名">{{info.expertVo && info.expertVo.nickName}}</el-descriptions-item>
		</el-descriptions>
  	<el-descriptions title="认证附件" direction="vertical" v-if="info.type==='company'">
		    <el-descriptions-item label="营业执照">
          <a :href="info.businessLicence" target="_blank">
            <img :src="info.businessLicence" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
		    <el-descriptions-item label="税务登记证">
          <a :href="info.taxRegistrationCertificate" target="_blank">
            <img :src="info.taxRegistrationCertificate" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
		</el-descriptions>
    <el-descriptions title="认证附件" direction="vertical" v-if="info.type==='user'">
		    <el-descriptions-item label="证件正面照">
          <a :href="info.idCardImgFront" target="_blank">
            <img :src="info.idCardImgFront" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
		    <el-descriptions-item label="证件反面照">
          <a :href="info.idCardImgBack" target="_blank">
            <img :src="info.idCardImgBack" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
		</el-descriptions>
    <el-descriptions title="认证附件" direction="vertical" v-if="info.type==='lawyer'">
		    <el-descriptions-item label="证件正面照">
          <a :href="info.idCardImgFront" target="_blank">
            <img :src="info.idCardImgFront" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
		    <el-descriptions-item label="证件反面照">
          <a :href="info.idCardImgBack" target="_blank">
            <img :src="info.idCardImgBack" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
        <el-descriptions-item label="律师执业证">
          <a :href="info.licenceImg" target="_blank">
            <img :src="info.licenceImg" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
        <el-descriptions-item label="职业形象照">
          <a :href="info.professionImg" target="_blank">
            <img :src="info.professionImg" style="width:88px;height:66px;"></img>
          </a>
        </el-descriptions-item>
		</el-descriptions>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false" type="primary">关 闭</el-button>
  </span>
</el-dialog>
</template>

<script>
import {mapState} from 'vuex'
    export default {
    	data(){
    		return{
    			dialogVisible:false
    		}
    	},
      props:['info'],
      computed:{
        ...mapState({
          registerSourceOptions:state => state.dictionary.registerSourceOptions,
          provinceObj:state=>state.dictionary.provinceObj,
          cityObj:state=>state.dictionary.cityObj,
          areaObj:state=>state.dictionary.areaObj
        })
      },
    	components:{},
    	methods:{}
    }
</script>

<style scoped lang="scss">

</style>